@import "variables";

.ligaSection {
    @include section;
    background-color: $white;
    
    .col {
        margin-top: 30px;
    }
    a {
        color: $black;
    &:hover {
        color: $black;
    }
}
}

.Count {
    padding: 20px;
    margin: 10px;

    border-radius: 20px;
    box-shadow: 1px 2px 2px #0D3B66;
    background-color: #FAF0CA;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #0D3B66;
}

